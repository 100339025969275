import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";

import Notice from "./Notice";
import Settings from "./Settings";
import { saveCookiesSettings, expandSettings, updateConsents } from "../../app/action";
import { isServer } from "../../helpers";
import styles from "./styles";

const useStyles = makeStyles(theme => styles(theme));

const mapDispatchToProps = dispatch => ({
  saveCookiesSettings: settings => dispatch(saveCookiesSettings(settings)),
  expandSettings: () => dispatch(expandSettings())
})

const mapStateToProps = state => ({
  settings: state.cookiesSettings,
})

function CookieModal({
  saveCookiesSettings,
  expandSettings,
  settings,
  button,
}) {
  const classes = useStyles();

  const [mounted, setMounted] = useState(false);

  React.useEffect(() => {
    setMounted(true);
    updateConsents(settings);
  }, [settings])

  function handleSaveSettings(config) {
    const {
      necessary,
      performance,
      personalisation,
      advertising,
      advertisingUserData,
      advertisingPersonalisation
    } = config;

    saveCookiesSettings({
      necessary,
      performance,
      personalisation,
      advertising,
      advertisingUserData,
      advertisingPersonalisation,
      saved: true,
      expanded: false
    })
  }

  if (isServer) return false;
  if (!mounted) return null;

  return (
    <>
      <Notice
        classes={classes}
        defaultSettings={settings}
        saveSettings={handleSaveSettings}
        expandSettings={expandSettings}
        button={button}
      />
      <Settings
        classes={classes}
        defaultSettings={settings}
        saveSettings={handleSaveSettings}
      />
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CookieModal)
