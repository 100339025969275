import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import TwitterIcon from "@material-ui/icons/Twitter"
import FacebookIcon from "@material-ui/icons/Facebook"
import InstagramIcon from "@material-ui/icons/Instagram"
import Link from "../Link"
import Button from "@material-ui/core/Button"
import { useStaticQuery, graphql } from "gatsby"
import RobFamilyLogo from "../../images/rob-family-logo.png"
import Grid from "@material-ui/core/Grid/Grid"
import ReactHtmlParser from "react-html-parser"
import IndiInnsBrand from "../../images/indi-inns-brand.png"
import IndiInnsFooterBg from "../../images/indi-inns-footer-bg.png"
import { expandSettings } from "../../app/action"
import { connect } from "react-redux"

const useStyles = makeStyles(theme => ({
  footer: {
    position: "relative",
    backgroundColor: theme.palette.secondary.main,
    padding: 12,
    width: "100vw",
    left: "50%",
    transform: "translateX(-50vw)",
    [theme.breakpoints.up("md")]: {
      padding: "88px 36px 0",
    },
    [theme.breakpoints.up("lg")]: {
      // '& .MuiGrid-grid-lg-5': {
      //     flexBasis: 480,
      // },
      // '& .MuiGrid-grid-lg-2': {
      //     flexBasis: 250,
      // },
      // '& .MuiGrid-grid-lg-3': {
      //     flexBasis: 'auto',
      //     maxWidth: 'none',
      // }
    },
    "& .site-logo": {
      textAlign: "center",
      position: "relative",
      padding: "32px 0 24px",
      [theme.breakpoints.up("md")]: {
        textAlign: "left",
      },
      "& img": {
        display: "block",
        height: 38,
        margin: "0 auto",
        [theme.breakpoints.up("lg")]: {
          height: "auto",
          margin: 0,
          maxWidth: "100%",
        },
      },
    },
    "& .footer-h": {
      color: theme.palette.secondary.contrastText,
      position: "relative",
      fontFamily: theme.headerFont,
      fontSize: 20,
      letterSpacing: "0.06em",
      lineHeight: "1.2",
      marginBottom: 24,
      textTransform: "uppercase",
    },
    "& a": {
      display: "block",
      color: theme.palette.secondary.contrastText,
      position: "relative",
      letterSpacing: "0.018em",
      lineHeight: 3.2,
      fontSize: 14,
    },
    "& .cookie": {
      display: "block",
      color: theme.palette.secondary.contrastText,
      position: "relative",
      padding: "0 !important",
      margin: "0 !important",
      letterSpacing: "0.018em",
      lineHeight: 3.2,
      fontSize: 14,
      border: "0 !important",
      background: "none !important",
      textTransform: "inherit !important",
      textDecoration: "underline",
      fontFamily: "inherit",
      textAlign: "left !important",
      boxShadow: "none !important",
    },
    "& p": {
      display: "block",
      color: theme.palette.secondary.contrastText,
      position: "relative",
      letterSpacing: "0.018em",
      lineHeight: 1.2,
      fontSize: 14,
    },
    "& button": {
      fontFamily: theme.headerFont,
      padding: "12px 22px",
      boxShadow: "0 1px 3px rgba(0,0,0,0.2)",
      fontSize: 16,
      lineHeight: 1.2,
      fontWeight: 400,
      textAlign: "center",
      width: "100%",
      textTransform: "uppercase",
      display: "block",
      [theme.breakpoints.up("md")]: {
        maxWidth: 195,
      },
    },
    "& .social-links": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      [theme.breakpoints.up("md")]: {
        justifyContent: "initial",
      },
      "& a": {
        textDecoration: "none",
        color: theme.palette.secondary.contrastText,
        [theme.breakpoints.up("md")]: {
          padding: 0,
        },
        "& svg": {
          width: 48,
          height: 48,
          verticalAlign: "middle",
        },
        "& span": {
          fontSize: 14,
          lineHeight: 1.2,
          letterSpacing: "0.018em",
          display: "none",
          fontFamily: "Roboto",
          marginRight: 32,
          [theme.breakpoints.up("lg")]: {
            display: "inline-block",
            verticalAlign: "middle",
          },
        },
      },
    },
    "& .rob-family": {
      textAlign: "center",
      display: "block",
      position: "relative",
      marginTop: 60,
      marginBottom: 80,
      "& a": {
        display: "inline-block",
      },
      "& img": {
        width: 205,
        margin: "0 auto",
      },
    },
    "&.indi-inns": {
      backgroundImage: "url(" + IndiInnsFooterBg + ")",
      backgroundSize: "auto 550px",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center bottom",
      [theme.breakpoints.up("md")]: {
        backgroundSize: "contain",
      },
      "& .rob-family": {
        marginTop: 225,
        marginBottom: 25,
      },
    },
  },
  footerTheme: {
    ...theme.footerTheme,
  },
}))

const mapDispatchToProps = dispatch => ({
  expandSettings: () => dispatch(expandSettings()),
})

const mapStateToProps = state => ({
  saved: state.cookiesSettings.saved,
})

function Footer(props) {
  const classes = useStyles()

  function openCookieSettings() {
    props.expandSettings()
  }

  const data = useStaticQuery(graphql`
    query FooterItems {
      wp {
        menus(where: { location: FOOTER_MENU }) {
          edges {
            node {
              id
              name
              slug
              menuItems {
                edges {
                  node {
                    id
                    title
                    url
                    path
                    label
                    target
                    order
                    childItems {
                      edges {
                        node {
                          id
                          title
                          url
                          path
                          label
                          target
                          order
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        themeOptions {
          ThemeOptions {
            footerLogo {
              sourceUrl
              sourceUrlSharp {
                id
                publicURL
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            individualInnsBranding
            surpressBranding
          }
        }
        locationData {
          LocationData {
            address1
            address2
            email
            facebookHandle
            instagramHandle
            name
            postcode
            town
            twitterHandle
            telephone
            region
          }
        }
      }
    }
  `)
  return (
    <div
      className={`footer ${
        data.wp.themeOptions.ThemeOptions.individualInnsBranding === true
          ? "indi-inns"
          : ""
      } ${classes.footer}  ${classes.footerTheme}`}
    >
      <Grid
        container
        spacing={3}
        direction="row"
        alignItems="flex-start"
        style={{ margin: 0, width: "100%" }}
      >
        <Grid item xs={12} md={12} lg={4}>
          <div className="site-logo">
            <Link to="/">
              <img
                src={
                  data.wp.themeOptions.ThemeOptions.footerLogo.sourceUrlSharp
                    .publicURL
                }
                alt={data.wp.locationData.LocationData.name}
              />
            </Link>
          </div>
        </Grid>
        <Grid item xs={12} md={4} lg={2}>
          <p className="footer-h">Contact Us</p>
          <p>
            {data.wp.locationData.LocationData.address1 !== null && (
              <span>
                {data.wp.locationData.LocationData.address1}, <br />
              </span>
            )}
            {data.wp.locationData.LocationData.address2 !== null && (
              <span>
                {data.wp.locationData.LocationData.address2}, <br />
              </span>
            )}
            {data.wp.locationData.LocationData.town !== null && (
              <span>
                {data.wp.locationData.LocationData.town}, <br />
              </span>
            )}
            {data.wp.locationData.LocationData.region !== null && (
              <span>
                {data.wp.locationData.LocationData.region}, <br />
              </span>
            )}
            {data.wp.locationData.LocationData.postcode !== null && (
              <span>{data.wp.locationData.LocationData.postcode} </span>
            )}
          </p>
          <Link to={"tel:" + data.wp.locationData.LocationData.telephone}>
            {data.wp.locationData.LocationData.telephone}
          </Link>
          <Link to={"mailto:" + data.wp.locationData.LocationData.email}>
            Email us
          </Link>
          <Link to="/contact-us/">Find us</Link>
        </Grid>
        <Grid item xs={12} md={4} lg={2}>
          <p className="footer-h">Information</p>
          <div className="footer-nav">
            {data.wp.menus.edges[0].node.menuItems.edges.map(section => (
              <Link key={section.node.path} to={section.node.path}>
                {ReactHtmlParser(section.node.label)}
              </Link>
            ))}
            <button className="cookie" onClick={e => openCookieSettings()}>
              Cookie control
            </button>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className="subscribe">
            <p className="footer-h">Subscribe Via Email</p>
            <p>
              Subscribe to our monthly newsletter and stay up to date with all
              news and events
            </p>
            <Link to="/subscribe/" style={{ textDecoration: "none" }}>
              <Button
                variant="contained"
                style={{ margin: "0 0 64px 0" }}
                color="primary"
              >
                Subscribe
              </Button>
            </Link>
          </div>
          <div className="social">
            <p className="footer-h">Social</p>
            <div className="social-links">
              {data.wp.locationData.LocationData.instagramHandle !== null && (
                <Link
                  to={
                    "https://www.instagram.com/" +
                    data.wp.locationData.LocationData.instagramHandle
                  }
                >
                  <InstagramIcon /> <span>Follow us</span>
                </Link>
              )}
              {data.wp.locationData.LocationData.twitterHandle !== null && (
                <Link
                  to={
                    "https://www.twitter.com/" +
                    data.wp.locationData.LocationData.twitterHandle
                  }
                >
                  <TwitterIcon /> <span>Follow us</span>
                </Link>
              )}
              {data.wp.locationData.LocationData.facebookHandle !== null && (
                <Link
                  to={
                    "https://www.facebook.com/" +
                    data.wp.locationData.LocationData.facebookHandle
                  }
                >
                  <FacebookIcon /> <span>Like us</span>
                </Link>
              )}
              <Link to={"https://www.tiktok.com/@robinsonsbrewery"}>
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 448 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"></path>
                </svg>{" "}
                <span>Follow us</span>
              </Link>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          {!data.wp.themeOptions.ThemeOptions.surpressBranding && (
            <div className="rob-family">
              {!data.wp.themeOptions.ThemeOptions.individualInnsBranding && (
                <Link to="https://www.robinsonsbrewery.com/">
                  <img
                    src={RobFamilyLogo}
                    alt="A part of the Robinsons Brewery Family"
                  />
                </Link>
              )}
              {data.wp.themeOptions.ThemeOptions.individualInnsBranding && (
                <Link to="https://www.individualinns.co.uk/">
                  <img src={IndiInnsBrand} alt="Individual Inns" />
                </Link>
              )}
            </div>
          )}
          {data.wp.themeOptions.ThemeOptions.surpressBranding && (
            <div className="rob-family">
              <span style={{ height: 120, display: "block" }} />
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
