import Cookies from 'js-cookie';

export function savePubSearchDataByName(data) {
  return {
    type: 'SAVE_DATA_BY_NAME',
    data
  }
}

export function savePubSearchDataByLocation(data) {
  return {
    type: 'SAVE_DATA_BY_LOCATION',
    data
  }
}

export function savePubSearchQuery(query) {
  return {
    type: 'SAVE_QUERY',
    query
  }
}

export function getGtagConsents(settings) {
  const consents = {
    ad_storage: 'denied',
    ad_user_data: 'denied',
    ad_personalization: 'denied',
    analytics_storage: 'denied',
    functionality_storage: 'denied',
    personalization_storage: 'denied',
    security_storage: 'denied',
  };
  if (settings.necessary) {
    consents.security_storage = 'granted';
    consents.functionality_storage = 'granted';
  }
  if (settings.performance) {
    consents.analytics_storage = 'granted';
  }
  if (settings.personalisation) {
    consents.personalization_storage = 'granted';
  }
  if (settings.advertising) {
    consents.ad_storage = 'granted';
  }
  if (settings.advertisingUserData) {
    consents.ad_user_data = 'granted';
  }
  if (settings.advertisingPersonalisation) {
    consents.ad_personalization = 'granted';
  }
  return consents;
}

export function updateConsents(settings) {
  const consents = getGtagConsents(settings);
  if (typeof window !== 'undefined') {
    if (typeof window.gtag !== 'function') {
      window.dataLayer = window.dataLayer || [];
      window.gtag = function () {
        window.dataLayer.push(arguments);
      }

      window.dataLayer.push({ ga_code: process.env.GATSBY_GA_CODE ? process.env.GATSBY_GA_CODE : "" });

      window.gtag('consent', 'default', {
        ad_storage: 'denied',
        ad_user_data: 'denied',
        ad_personalization: 'denied',
        analytics_storage: 'denied',
        functionality_storage: 'denied',
        personalization_storage: 'denied',
        security_storage: 'denied',
      });
    }
    window.gtag('consent', 'update', consents);
  }
}

export function saveCookiesSettings(settings) {
  Cookies.set('gdpr', JSON.stringify(settings), { expires: 1095 });
  updateConsents(settings);

  return {
    type: 'SAVE_COOKIES_SETTINGS',
    settings
  }
}

export function unhideModal() {
  return {
    type: 'UNHIDE_MODAL'
  }
}

export function expandSettings() {
  return {
    type: 'EXPAND_SETTINGS'
  }
}
