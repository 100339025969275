import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Link from '../Link';
import { useStaticQuery, graphql } from "gatsby";

const useStyles = makeStyles(theme => ({
    subNav: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        marginBottom: 50,
        alignItems: 'flex-start',
        width: 'calc(100% + 20px)',
        margin: '0 -10px',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'space-between',
        },
        '& a': {
            fontFamily: theme.headerFont,
            color: theme.palette.secondary.contrastText,
            textDecoration: 'none',
            margin: '0 20px 10px',
            fontSize: 18,
            lineHeight: 2,
            padding: '0 10px',
            [theme.breakpoints.up('md')]: {
                margin: '0 0 10px',
            },
            '&[aria-current="page"], &[class]': {
                opacity: 0.5,
                '&::after': {
                    width: 30,
                    margin: '2px auto 0',
                    height: 1,
                    backgroundColor: theme.palette.secondary.contrastText,
                    content: '""',
                    display: 'block',
                }
            }
        }
    },
}));

export default function TopNav(props) {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
      query TopSubNavItems {
        wp {
          menus(where: {location: HEADER_SUB_MENU}) {
            edges {
              node {
                id
                name
                slug
                menuItems {
                  edges {
                    node {
                      id
                      title
                      url
                      path
                      label
                      target
                      order
                      childItems {
                        edges {
                          node {
                            id
                            title
                            url
                            path
                            label
                            target
                            order
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `);
  return (
      <React.Fragment>
          <Toolbar component="nav" disableGutters={true} className={`sub-nav  ${classes.subNav}`}>
              {data.wp.menus.edges[0].node.menuItems.edges.map(section => (
                  <Link
                      key={section.node.path}
                      to={section.node.path}
                  >
                    {section.node.label}
                  </Link>
              ))}
          </Toolbar>
      </React.Fragment>
  );
}