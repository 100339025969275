import React, { useState } from 'react';

export default function Settings({ classes, defaultSettings, saveSettings }) {
  const [necessary, setNecessary] = useState(defaultSettings.necessary);
  const [performance, setPerformance] = useState(defaultSettings.performance);
  const [personalisation, setPersonalisation] = useState(defaultSettings.personalisation);
  const [advertising, setAdvertising] = useState(defaultSettings.advertising);
  const [advertisingUserData, setAdvertisingUserData] = useState(defaultSettings.advertisingUserData);
  const [advertisingPersonalisation, setAdvertisingPersonalisation] = useState(defaultSettings.advertisingPersonalisation);

  function handleSaveSettings() {
    saveSettings({
      necessary,
      performance,
      personalisation,
      advertising,
      advertisingUserData,
      advertisingPersonalisation
    })
  }

  React.useEffect(() => {
    const {
      necessary,
      performance,
      personalisation,
      advertising,
      advertisingUserData,
      advertisingPersonalisation
    } = defaultSettings;

    setNecessary(necessary);
    setPerformance(performance);
    setPersonalisation(personalisation);
    setAdvertising(advertising);
    setAdvertisingUserData(advertisingUserData);
    setAdvertisingPersonalisation(advertisingPersonalisation);
  }, [defaultSettings])

  if (!defaultSettings.expanded) return null;

  return <aside className={classes.CookieModal}>
  <article className={classes.Content}>
    <h2>Cookie Settings</h2>
    <button className={classes.Close} onClick={() => handleSaveSettings()}>X</button>

    <div>
      <label className={classes.Option}>
        <input type="checkbox" className={classes.Checkbox} checked={necessary} onChange={e => setNecessary(true)}/>
        <span className="checkmark disabled"></span>
        Strictly Necessary
      </label>
      <p>These cookies are required for our website to operate and include items such as whether or not to display this pop-up box or your session when logging in to the website. These cookies cannot be disabled.</p>
    </div>

    <div>
      <label className={classes.Option}>
        <input type="checkbox" className={classes.Checkbox} checked={performance} onChange={e => setPerformance(!performance)}/>
        <span className="checkmark"></span>
        Performance
      </label>
      <p>We use 3rd party services such as Google Analytics to measure the performance of our website. This helps us tailor the site content to our visitors needs.</p>
    </div>

    <div>
      <label className={classes.Option}>
      <input type="checkbox" className={classes.Checkbox} checked={personalisation} onChange={e => setPersonalisation(!personalisation)}/>
        <span className="checkmark"></span>
        Personalisation
      </label>
      <p>From time to time, we may use cookies to store key pieces of information related to personalisation. This allows us to enhance your browsing experience by adapting our website based on your preferences.</p>
    </div>

    <div>
      <label className={classes.Option}>
        <input type="checkbox" className={classes.Checkbox} checked={advertising} onChange={e => setAdvertising(!advertising)}/>
        <span className="checkmark"></span>
        Advertising
      </label>
      <p>We may use advertising services that store cookies related to advertising. These cookies are not required but may improve the services we offer and promote.</p>
    </div>

    <div>
      <label className={classes.Option}>
        <input type="checkbox" className={classes.Checkbox} checked={advertisingUserData} onChange={e => setAdvertisingUserData(!advertisingUserData)}/>
        <span className="checkmark"></span>
        Advertising user data
      </label>
      <p>This setting grants permission for any advertising services we may use to access your user data.</p>
    </div>

    <div>
      <label className={classes.Option}>
        <input type="checkbox" className={classes.Checkbox} checked={advertisingPersonalisation} onChange={e => setAdvertisingPersonalisation(!advertisingPersonalisation)} />
        <span className="checkmark"></span>
        Advertising personalisation
      </label>
      <p>This setting allows any advertising services we may use to present you with personalised content that may be more relevant to you.</p>
    </div>

    <div className={classes.Actions}>
      <button onClick={() => handleSaveSettings()}>Save</button>
      <button onClick={() => handleSaveSettings()}>Close</button>
    </div>
  </article>
</aside>;
}
