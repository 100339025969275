import React from "react"

export default function Notice({
  classes,
  defaultSettings,
  expandSettings,
  saveSettings,
  button,
}) {
  function handleSaveAllSettings() {
    saveSettings({
      necessary: true,
      performance: true,
      personalisation: true,
      advertising: true,
      advertisingUserData: true,
      advertisingPersonalisation: true,
      saved: true,
      expanded: false
    });
  }

  if (!defaultSettings.saved) {
    return (
      <>
        <div className={classes.movedButton}>{button}</div>
        <div className={classes.Notice}>
          <p>
            Welcome. We use cookies to offer you a better website experience.
          </p>

          <button onClick={e => expandSettings()}>
            Change settings
          </button>

          <button onClick={e => handleSaveAllSettings()}>
            Accept all
          </button>
        </div>
      </>
    )
  }

  return button
}
