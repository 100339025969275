export default theme => ({
  movedButton: {
    "& > a": {
      bottom: 150,
      [theme.breakpoints.up("md")]: {
        bottom: 210,
      },
    },
  },
  CookieModal: {
    position: "fixed",
    display: "flex",
    boxShadow: "0 4px 6px rgba(0,0,0,0.13)",
    zIndex: 102,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    alignItems: "center",
    justifyContent: "center",
  },
  Content: {
    position: "relative",
    width: "calc(100% - 24px)",
    maxWidth: "50em",
    maxHeight: "calc(100vh - 24px)",
    padding: 24,
    alignSelf: "center",
    backgroundColor: theme.palette.background.default,
    boxShadow: "0 4px 6px rgba(0,0,0,0.13)",
    overflowY: "scroll",
    "& > div": {
      marginBottom: 24,
      borderBottom: "1px solid rgba(0,0,0,0.2)",
    },
    "& h2": {
      textTransform: "uppercase",
      letterSpacing: "0.056em",
      marginTop: 0,
    },
    "& p": {
      fontSize: 12,
      lineHeight: 2,
      letterSpacing: "0.04em",
    },
  },
  Actions: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "none !important",
    marginBottom: "0 !important",
    "& button": {
      display: "table",
      border: "none",
      backgroundColor: "transparent",
      borderBottom: "2px solid " + theme.palette.primary.main,
      color: theme.palette.primary.main,
      padding: "0 0 6px",
      fontFamily: theme.headerFont,
      textTransform: "uppercase",
      letterSpacing: "0.04em",
      transition: "color .3s ease-in-out, border-color .3s ease-in-out",
      outline: "none !important",
      "&:hover, &:focus": {
        color: theme.palette.secondary.main,
        borderColor: theme.palette.secondary.main,
      },
    },
  },
  Option: {
    cursor: "pointer",
    position: "relative",
    display: "table",
    paddingLeft: 36,
    fontSize: 14,
    lineHeight: "18px",
    letterSpacing: "0.032em",
    marginBottom: 16,
    marginTop: 16,
    "& input": {
      position: "absolute",
      opacity: 0,
      cursor: "pointer",
      width: 0,
      height: 0,
      margin: 0,
      top: 0,
      right: 0,
    },
    "& .checkmark": {
      position: "absolute",
      cursor: "pointer",
      top: 0,
      left: 0,
      height: 18,
      width: 18,
      backgroundColor: theme.palette.common.white,
      border: "1px solid " + theme.palette.primary.main,
      "&::after": {
        content: '""',
        position: "absolute",
        display: "none",
        left: 5,
        top: 2,
        width: 4,
        height: 8,
        border: "solid " + theme.palette.primary.contrastText,
        borderWidth: "0 2px 2px 0",
        transform: "rotate(45deg)",
        boxSizing: "content-box",
      },
      "&.disabled": {
        opacity: 0.4,
      },
    },
    "&:hover input ~ .checkmark": {
      border: "1px solid " + theme.palette.primary.dark,
    },
    "& input:checked ~ .checkmark": {
      backgroundColor: theme.palette.primary.main,
    },
    "& input:checked ~ .checkmark::after": {
      display: "block",
    },
  },
  Close: {
    position: "absolute",
    top: 24,
    right: 24,
    backgroundColor: theme.palette.common.black,
    width: 18,
    height: 18,
    clipPath:
      "polygon(0 10%, 40% 50%, 0 90%, 10% 100%, 50% 60%, 90% 100%, 100% 90%, 60% 50%, 100% 10%, 90% 0, 50% 40%, 10% 0%)",
    display: "inline-block",
    verticalAlign: "text-top",
    fontSize: 0,
    border: "none",
    outline: "none",
  },
  Checkbox: {
    filter: "opacity(0.4) grayscale(1)",
  },
  Notice: {
    position: "fixed",
    display: "flex",
    backgroundColor: theme.palette.secondary.main,
    boxShadow: "0 4px 6px rgba(0,0,0,0.13)",
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 101,
    padding: 24,
    justifyContent: "space-between",
    flexDirection: "row",
    flexWrap: "wrap",
    // [theme.breakpoints.up("sm")]: {
    //   bottom: 24,
    //   right: 24,
    //   left: "auto",
    //   width: 320,
    //   padding: "24px 36px",
    // },
    [theme.breakpoints.up("md")]: {
      bottom: 36,
      right: 50,
      left: "auto",
      width: 460,
      padding: "36px 48px",
    },
    "& p": {
      fontSize: 14,
      letterSpacing: "0.04em",
      marginBottom: 24,
      color: theme.palette.secondary.contrastText,
      lineHeight: 1.5,
      display: "block",
      flex: "0 0 100%",
      minWidth: "100%",
      marginTop: 0,
    },
    "& button": {
      display: "inline-block",
      border: "none",
      backgroundColor: "transparent",
      borderBottom: "2px solid " + theme.palette.primary.main,
      color: theme.palette.primary.main,
      padding: "0 0 6px",
      fontFamily: theme.headerFont,
      textTransform: "uppercase",
      letterSpacing: "0.04em",
      transition: "color .3s ease-in-out, border-color .3s ease-in-out",
      outline: "none !important",
      "&:hover, &:focus": {
        color: theme.palette.secondary.contrastText,
        borderColor: theme.palette.secondary.contrastText,
      },
    },
  },
})
