import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Toolbar from "@material-ui/core/Toolbar"
import PhoneEnabledIcon from "@material-ui/icons/PhoneEnabled"
import EmailIcon from "@material-ui/icons/Email"
import TwitterIcon from "@material-ui/icons/Twitter"
import FacebookIcon from "@material-ui/icons/Facebook"
import InstagramIcon from "@material-ui/icons/Instagram"
import Link from "../Link"
import Button from "@material-ui/core/Button"
import SubNav from "../SubNav"
import { useStaticQuery, graphql } from "gatsby"
import RobFamilyLogo from "../../images/rob-family-logo.png"
import RobHeaderLogo from "../../images/rob-header-logo.png"
import IndiInnsBrand from "../../images/indi-inns-brand.png"
import IndiInnsHeader from "../../images/indi-inns-header.png"

const useStyles = wedding =>
  makeStyles(theme => ({
    shade: {
      position: "fixed",
      display: "block",
      content: '""',
      top: "0",
      left: "0",
      right: "0",
      height: 52,
      zIndex: "2",
      background:
        "linear-gradient(to bottom, rgba(0,0,0,1) 0%,rgba(0,0,0,0.84) 37%,rgba(0,0,0,0) 100%)",
      opacity: 0.3,
      transition: wedding ? "opacity 0.3s ease-in-out" : "",
      [theme.breakpoints.up("md")]: {
        height: 115,
        opacity: "0.4",
        background:
          "linear-gradient(to bottom, rgba(0,0,0,1) 0%,rgba(0,0,0,1) 33%,rgba(0,0,0,0) 100%)",
      },
      "&.scrolled": {
        position: wedding ? "absolute" : "fixed",
      },
      "&.faded": {
        opacity: wedding ? 0 : 0.3,
      },
    },
    shadeTheme: {
      ...theme.shadeTheme,
    },
    topNav: {
      backgroundColor: theme.palette.secondary.main,
      padding: 12,
      position: "fixed",
      top: 28,
      left: 0,
      right: 0,
      zIndex: "10",
      boxShadow: "0 5px 8px rgba(0,0,0,0.1)",
      opacity: 1,
      transition: wedding ? "opacity 0.3s ease-in-out" : "",
      [theme.breakpoints.up("md")]: {
        top: 50,
        left: 50,
        right: 50,
        borderRadius: 6,
        boxShadow: "0 8px 16px rgba(46,41,37,0.58)",
        padding: "18px 24px",
      },
      "&.scrolled": {
        position: wedding ? "absolute" : "fixed",
      },
      "&.faded": {
        opacity: wedding ? 0 : 1,
      },
      "& *": {
        opacity: 1,
      },
      "&[open] *": {
        opacity: 0,
      },
      "&::after": {
        content: '""',
        display: "table",
        clear: "both",
      },
      "& .rob-header-logo": {
        margin: "-8px auto 20px",
        [theme.breakpoints.up("md")]: {
          margin: "-5px auto 30px",
        },
        "& img": {
          display: "block",
          height: 16,
          margin: "0 auto",
          position: "relative",
          zIndex: "1",
          [theme.breakpoints.up("md")]: {
            height: 25,
          },
        },
      },
      "& .site-logo": {
        maxWidth: "50%",
        display: "block",
        margin: "0 auto",
        textAlign: "center",
        "& img": {
          maxHeight: 30,
          [theme.breakpoints.up("lg")]: {
            maxHeight: 38,
          },
        },
        [theme.breakpoints.up("lg")]: {
          width: "auto",
          float: "left",
          textAlign: "left",
        },
      },
      "& nav": {
        display: "none",
        [theme.breakpoints.up("lg")]: {
          display: "block",
          marginTop: 12,
        },
        float: "right",
        minHeight: 0,
        "& a": {
          fontSize: 20,
          fontFamily: theme.headerFont,
          color: theme.palette.secondary.contrastText,
          textDecoration: "none",
          padding: "0 35px",
          textTransform: "uppercase",
          transition: "opacity 250ms ease-in-out",
          opacity: 1,
          display: "inline-block",
          lineHeight: "1",
          "&:hover, &:focus": {
            opacity: "0.8",
          },
          '&[aria-current="page"], &[class]': {
            opacity: 0.5,
            "&::after": {
              width: 30,
              margin: "2px auto 0",
              height: 1,
              backgroundColor: theme.palette.secondary.contrastText,
              content: '""',
              display: "block",
            },
          },
        },
      },
      "& .open-menu": {
        position: "absolute",
        top: "calc(50% - 11px)",
        right: 14,
        fontSize: 0,
        width: 28,
        height: 22,
        padding: 0,
        textAlign: "right",
        minWidth: 0,
        [theme.breakpoints.up("lg")]: {
          fontSize: 20,
          color: theme.palette.secondary.contrastText,
          width: "auto",
          position: "relative",
          float: "right",
          marginLeft: 35,
          height: 20,
          marginTop: 12,
          lineHeight: "1",
        },
        "& .MuiButton-label": {
          "& span": {
            display: "block",
            position: "relative",
            height: 4,
            backgroundColor: theme.palette.secondary.contrastText,
            borderRadius: 2,
            width: 20,
            margin: "0 0 0 auto",
            transition:
              "width 250ms ease-in-out, margin-left 250ms ease-in-out",
            [theme.breakpoints.up("lg")]: {
              transform: "scale(0.6)",
              margin: "-4px 0 0 14px",
            },
            "&::after, &::before": {
              content: '""',
              backgroundColor: theme.palette.secondary.contrastText,
              position: "absolute",
              height: 4,
              borderRadius: 2,
              transition: "width 250ms ease-in-out",
              right: 0,
            },
            "&::after": {
              width: 28,
              top: 9,
            },
            "&::before": {
              width: 14,
              bottom: 9,
            },
          },
        },
        "&:hover, &:focus": {
          "& .MuiButton-label span": {
            width: 28,
            [theme.breakpoints.up("lg")]: {
              marginLeft: 6,
            },
            "&::before, &::after": {
              width: 28,
            },
          },
        },
      },
    },
    fullNav: {
      position: "fixed",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 100,
      display: "none",
      backgroundSize: "cover",
      backgroundPosition: "center",
      "&[open]": {
        display: "block",
      },
      "& .overlay": {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "" + theme.palette.secondary.main + "EB",
        padding: 24,
        overflow: "scroll",
        [theme.breakpoints.up("md")]: {
          padding: 70,
          overflow: "hidden",
        },
        "& .close-menu": {
          color: theme.palette.secondary.contrastText,
          fontSize: 16,
          letterSpacing: "0.016em",
          lineHeight: 1.5,
          margin: "0 0 30px auto",
          display: "block",
          verticalAlign: "middle",
          "& .MuiButton-label": {
            "& span": {
              backgroundColor: theme.palette.secondary.contrastText,
              width: 18,
              height: 18,
              clipPath:
                "polygon(7px 1px, 8px 0px, 10px 0px, 11px 1px, 11px 7px, 17px 7px, 18px 8px, 18px 10px, 17px 11px, 11px 11px, 11px 17px, 10px 18px, 8px 18px, 7px 17px, 7px 11px, 1px 11px, 0px 10px, 0px 8px, 1px 7px, 7px 7px)",
              display: "inline-block",
              verticalAlign: "text-top",
              transform: "rotate(45deg)",
            },
          },
          [theme.breakpoints.up("md")]: {
            float: "right",
          },
        },
        "& .site-logo": {
          maxWidth: "50%",
          display: "block",
          margin: "0 auto 30px",
          textAlign: "center",
          "& img": {
            maxHeight: 25,
          },
          [theme.breakpoints.up("md")]: {
            float: "left",
            "& img": {
              maxHeight: 38,
            },
          },
        },
        "& .nav-wrapper": {
          [theme.breakpoints.up("md")]: {
            clear: "both",
            width: 320,
            float: "left",
            margin:
              "0 calc(((100% - 640px) / 3) / 2) 0 calc((100% - 640px) / 3)",
            paddingTop: 50,
          },
        },
        "& nav.main-nav-stacked": {
          flexDirection: "column",
          [theme.breakpoints.up("md")]: {
            alignItems: "flex-start",
          },
          "& a": {
            fontFamily: theme.headerFont,
            color: theme.palette.secondary.contrastText,
            fontSize: 24,
            lineHeight: 1.5,
            textDecoration: "none",
            marginBottom: 15,
            '&[aria-current="page"], &[class]': {
              opacity: 0.5,
              "&::after": {
                width: 30,
                margin: "2px auto 0",
                height: 1,
                backgroundColor: theme.palette.secondary.contrastText,
                content: '""',
                display: "block",
              },
            },
          },
        },
        "& .booking-button": {
          fontFamily: theme.headerFont,
          padding: "12px 22px",
          boxShadow: "0 1px 3px rgba(0,0,0,0.2)",
          fontSize: 18,
          letterSpacing: "0.06em",
          lineHeight: 1.5,
          fontWeight: 400,
          textAlign: "center",
          minWidth: 185,
          width: "100%",
          textTransform: "uppercase",
        },
        "& .house-data": {
          borderTop: "1px solid",
          borderColor: theme.palette.secondary.contrastText,
          marginTop: 35,
          paddingTop: 30,
          [theme.breakpoints.up("md")]: {
            width: 320,
            float: "left",
            margin:
              "0 calc((100% - 640px) / 3) 0 calc(((100% - 640px) / 3) / 2)",
            borderTop: "none",
            paddingTop: 0,
          },
          "& .data": {
            [theme.breakpoints.up("md")]: {
              display: "flex",
              flexDirection: "column-reverse",
            },
            "& .actions": {
              "& a": {
                backgroundColor: theme.palette.secondary.contrastText,
                color: theme.palette.secondary.main,
                textDecoration: "none",
                margin: "0 auto 16px",
                display: "block",
                borderRadius: 4,
                boxShadow: "0 1px 3px rgba(0,0,0,0.2)",
                padding: 12,
                fontFamily: theme.headerFont,
                maxWidth: "80%",
                transition: "opacity 250ms ease-in-out",
                opacuty: "1",
                [theme.breakpoints.up("md")]: {
                  backgroundColor: "transparent",
                  padding: 0,
                  maxWidth: "none",
                  display: "block",
                  color: theme.palette.secondary.contrastText,
                  margin: "0 auto 5px",
                  textAlign: "center",
                  boxShadow: "none",
                  "&:hover, &:focus": {
                    opacity: "0.8",
                  },
                },
                "& svg": {
                  verticalAlign: "middle",
                  width: 36,
                  borderRight: ".5px solid",
                  borderColor: theme.palette.secondary.main,
                  paddingRight: 12,
                  [theme.breakpoints.up("md")]: {
                    width: 16,
                    height: 16,
                    borderRight: "none",
                    paddingRight: 0,
                  },
                },
                "& span": {
                  verticalAlign: "middle",
                  display: "inline-block",
                  paddingLeft: 12,
                  width: "calc(100% - 36px)",
                  textAlign: "center",
                  [theme.breakpoints.up("md")]: {
                    width: "auto",
                  },
                },
                "&.tel-link": {
                  "& span": {
                    fontSize: 18,
                    letterSpacing: "0.06em",
                    [theme.breakpoints.up("md")]: {
                      fontSize: 14,
                    },
                  },
                },
                "&.email-link": {
                  "& span": {
                    fontSize: 18,
                    letterSpacing: "0.06em",
                    [theme.breakpoints.up("md")]: {
                      fontSize: 14,
                    },
                  },
                },
              },
            },
            "& .address": {
              "& p": {
                color: theme.palette.secondary.contrastText,
                fontSize: 16,
                lineHeight: "1.5",
                marginBottom: 30,
                textAlign: "center",
                padding: "0 30px",
                "&.location-name": {
                  textTransform: "uppercase",
                  fontFamily: theme.subHeaderFont,
                  letterSpacing: "0.04em",
                  fontWeight: "700",
                  fontSize: 20,
                  lineHeight: 1.2,
                  marginBottom: 10,
                  [theme.breakpoints.up("md")]: {
                    marginTop: 0,
                  },
                },
              },
            },
          },
          "& .social-links": {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            [theme.breakpoints.up("md")]: {
              borderTop: "1px solid",
              borderTopColor: theme.palette.secondary.contrastText,
              marginTop: 20,
            },
            "& a": {
              color: theme.palette.secondary.contrastText,
              [theme.breakpoints.up("md")]: {
                padding: "30px 0 15px",
              },
              "& svg": {
                width: 48,
                height: 48,
              },
            },
          },
        },
        "& .rob-family": {
          textAlign: "center",
          display: "none",
          [theme.breakpoints.up("md")]: {
            paddingBottom: 30,
            borderBottom: "1px solid",
            borderBottomColor: theme.palette.secondary.contrastText,
            marginBottom: 30,
          },
          "& img": {
            width: 205,
            margin: "0 auto",
          },
          "&.mob": {
            display: "block",
            [theme.breakpoints.up("md")]: {
              display: "none",
            },
          },
          "&.desk": {
            [theme.breakpoints.up("md")]: {
              display: "block",
            },
          },
        },
      },
    },
    topNavTheme: {
      ...theme.topNavTheme,
    },
  }))

export default function TopNav(props) {
  const [open, setOpen] = React.useState(false)

  const [scrollPosition, setScrollPosition] = useState(0)
  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true })

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const handleFullMenuOpen = () => {
    setOpen(!open)
  }
  const classes = useStyles(props?.pageContext?.wedding)()
  const data = useStaticQuery(graphql`
    query TopNavItems {
      wp {
        menus(where: { location: HEADER_MENU }) {
          edges {
            node {
              id
              name
              slug
              menuItems {
                edges {
                  node {
                    id
                    title
                    url
                    path
                    label
                    target
                    order
                    childItems {
                      edges {
                        node {
                          id
                          title
                          url
                          path
                          label
                          target
                          order
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        themeOptions {
          accommodationActive
          tableBookingActive
          ThemeOptions {
            siteLogo {
              sourceUrl
              sourceUrlSharp {
                id
                publicURL
                childImageSharp {
                  fluid(quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            navBackgroundImage {
              sourceUrl
              sourceUrlSharp {
                id
                publicURL
                childImageSharp {
                  fluid(quality: 60, maxWidth: 1660) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            individualInnsBranding
            surpressBranding
          }
        }
        locationData {
          LocationData {
            address1
            address2
            email
            facebookHandle
            instagramHandle
            name
            postcode
            town
            twitterHandle
            telephone
            region
          }
        }
      }
    }
  `)

  let image = null

  if (data.wp.themeOptions.ThemeOptions.navBackgroundImage.sourceUrlSharp) {
    image =
      data.wp.themeOptions.ThemeOptions.navBackgroundImage.sourceUrlSharp
        .childImageSharp.fluid.srcWebp
  } else {
    image =
      data.wp.themeOptions.ThemeOptions.navBackgroundImage.sourceUrlSharp
        .publicURL
  }
  return (
    <React.Fragment>
      <div
        className={`shade  ${classes.shade}  ${classes.shadeTheme} ${
          scrollPosition > 600 ? "scrolled" : ""
        } ${scrollPosition > 575 ? "faded" : ""}`}
      />
      <div
        className={`top-nav  ${classes.topNav}  ${classes.topNavTheme} ${
          scrollPosition > 600 ? "scrolled" : ""
        } ${scrollPosition > 575 ? "faded" : ""}`}
        open={open}
      >
        {!data.wp.themeOptions.ThemeOptions.surpressBranding && (
          <div className="rob-header-logo">
            {!data.wp.themeOptions.ThemeOptions.individualInnsBranding && (
              <Link to="https://www.robinsonsbrewery.com/">
                <img src={RobHeaderLogo} alt="Robinsons" />
              </Link>
            )}
            {data.wp.themeOptions.ThemeOptions.individualInnsBranding && (
              <Link to="https://www.individualinns.co.uk/">
                <img src={IndiInnsHeader} alt="Individual Inns" />
              </Link>
            )}
          </div>
        )}
        <div className="site-logo">
          <Link to="/">
            <img
              src={
                data.wp.themeOptions.ThemeOptions.siteLogo.sourceUrlSharp
                  .publicURL
              }
              alt={data.wp.locationData.LocationData.name}
            />
          </Link>
        </div>
        {data.wp.menus.edges[0].node.menuItems.edges.length > 0 && (
          <Button className="open-menu" onClick={handleFullMenuOpen}>
            More <span></span>
          </Button>
        )}
        <Toolbar component="nav" disableGutters={true}>
          {data.wp.menus.edges[0].node.menuItems.edges.map(section => (
            <Link key={section.node.path} to={section.node.path}>
              {section.node.label}
            </Link>
          ))}
        </Toolbar>
      </div>
      <div
        className={`full-nav  ${classes.fullNav}`}
        open={open}
        style={{ backgroundImage: "url(" + image + ")" }}
      >
        <div className="overlay">
          <Button className="close-menu" onClick={handleFullMenuOpen}>
            Close <span></span>
          </Button>
          <div className="site-logo">
            <Link to="/">
              <img
                src={
                  data.wp.themeOptions.ThemeOptions.siteLogo.sourceUrlSharp
                    .publicURL
                }
                alt={data.wp.locationData.LocationData.name}
              />
            </Link>
          </div>
          <div className="nav-wrapper">
            <Toolbar
              className="main-nav-stacked"
              component="nav"
              disableGutters={true}
            >
              {data.wp.menus.edges[0].node.menuItems.edges.map(section => (
                <Link key={section.node.path} to={section.node.path}>
                  {section.node.label}
                </Link>
              ))}
            </Toolbar>
            <SubNav />
            {data.wp.themeOptions.accommodationActive === "true" ||
            data.wp.themeOptions.tableBookingActive === "true" ? (
              <Link to={props.buttonLink} style={{ textDecoration: "none" }}>
                <Button
                  className="booking-button"
                  style={{ margin: 0 }}
                  variant="contained"
                  color="primary"
                >
                  {props.buttonText}
                </Button>
              </Link>
            ) : (
              ""
            )}
          </div>
          <div className="house-data">
            <div className="rob-family desk">
              {!data.wp.themeOptions.ThemeOptions.individualInnsBranding && (
                <Link to="https://www.robinsonsbrewery.com/">
                  <img
                    src={RobFamilyLogo}
                    alt="A part of the Robinsons Brewery Family"
                  />
                </Link>
              )}
              {data.wp.themeOptions.ThemeOptions.individualInnsBranding && (
                <Link to="https://www.individualinns.co.uk/">
                  <img src={IndiInnsBrand} alt="Individual Inns" />
                </Link>
              )}
            </div>
            <div className="data">
              <div className="actions">
                <Link
                  to={"tel:" + data.wp.locationData.LocationData.telephone}
                  className="tel-link"
                >
                  <PhoneEnabledIcon />
                  <span>{data.wp.locationData.LocationData.telephone}</span>
                </Link>
                <Link
                  to={"mailto:" + data.wp.locationData.LocationData.email}
                  className="email-link"
                >
                  <EmailIcon />
                  <span>Email us</span>
                </Link>
              </div>
              <div className="address">
                <p className="location-name">
                  {data.wp.locationData.LocationData.name}
                </p>
                <p>
                  {data.wp.locationData.LocationData.address1 !== null && (
                    <span>{data.wp.locationData.LocationData.address1}, </span>
                  )}
                  {data.wp.locationData.LocationData.address2 !== null && (
                    <span>{data.wp.locationData.LocationData.address2}, </span>
                  )}
                  {data.wp.locationData.LocationData.town !== null && (
                    <span>{data.wp.locationData.LocationData.town}, </span>
                  )}
                  {data.wp.locationData.LocationData.region !== null && (
                    <span>{data.wp.locationData.LocationData.region}, </span>
                  )}
                  {data.wp.locationData.LocationData.postcode !== null && (
                    <span>{data.wp.locationData.LocationData.postcode} </span>
                  )}
                </p>
              </div>
            </div>
            <div className="social-links">
              {data.wp.locationData.LocationData.instagramHandle !== null && (
                <Link
                  to={
                    "https://www.instagram.com/" +
                    data.wp.locationData.LocationData.instagramHandle
                  }
                >
                  <InstagramIcon />
                </Link>
              )}
              {data.wp.locationData.LocationData.twitterHandle !== null && (
                <Link
                  to={
                    "https://www.twitter.com/" +
                    data.wp.locationData.LocationData.twitterHandle
                  }
                >
                  <TwitterIcon />
                </Link>
              )}
              {data.wp.locationData.LocationData.facebookHandle !== null && (
                <Link
                  to={
                    "https://www.facebook.com/" +
                    data.wp.locationData.LocationData.facebookHandle
                  }
                >
                  <FacebookIcon />
                </Link>
              )}
            </div>
            <div className="rob-family mob">
              {!data.wp.themeOptions.ThemeOptions.individualInnsBranding && (
                <Link to="https://www.robinsonsbrewery.com/">
                  <img
                    src={RobFamilyLogo}
                    alt="A part of the Robinsons Brewery Family"
                  />
                </Link>
              )}
              {data.wp.themeOptions.ThemeOptions.individualInnsBranding && (
                <Link to="https://www.individualinns.co.uk/">
                  <img src={IndiInnsBrand} alt="Individual Inns" />
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
